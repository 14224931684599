import { template as template_bee3149667194ff4914aaf0f59ef0ad8 } from "@ember/template-compiler";
const FKLabel = template_bee3149667194ff4914aaf0f59ef0ad8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
