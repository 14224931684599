import { template as template_cf82e3dcf2a847d9a6edb05b3f3912de } from "@ember/template-compiler";
const FKControlMenuContainer = template_cf82e3dcf2a847d9a6edb05b3f3912de(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
