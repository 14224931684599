import { template as template_4ea776c3c16e4c19a96569a4725e4032 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_4ea776c3c16e4c19a96569a4725e4032(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
