import { template as template_62ee1615ba7340cbb93ee55f69e84f9f } from "@ember/template-compiler";
const FKText = template_62ee1615ba7340cbb93ee55f69e84f9f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
